.highlight-3 {
  background: #CBD5E0;
}

.blue {
  background: #75ABBC;
}

.text-white {
  color: #fff;
}


.text-black {
  color: #151417;
}

.square {
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  align-items: center;
  padding-bottom: 0.3rem;
  user-select: none;
  font-size: 2rem;
  box-sizing: border-box;
}

@media screen and (max-width: 484px) {
  .square {
    width: 2rem;
    height: 2rem;
  }
}