.small-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 927px;
  align-items: center;
}

.variants-container {
  margin-top: 64px;

  &:first-of-type {
    margin-top: 0;
  }
}

.board-variants-container {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
}

.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;

  pre {
    margin-top: 64px;
    font-size: 32px;
  }
}

.board-card {
  box-sizing: border-box;
  width: 448px;
  min-height: 512px;
  padding: 32px 18px;
  width: 448px;

  pre {
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    margin-top: 32px;
  }
}

.crown-title {
  margin-top: 64px;
}

.crown-subtitle {
  margin-top: 17px;
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0px;
  color: #4A4A4A;
  width: 927px;
}

.normal-board {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 100;
  opacity: 1;
}

.input-board {
  position: relative;
  width: 24rem;
  height: 24rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  user-select: none;
  border-width: 15px;
  border-style: solid;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  border-color: #23B5D3;
  border-style:groove;
}

.piece-shelf-container {
  position: relative;
  width: 24rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  margin-top: 30px;
}

.piece-shelf {
  justify-content: center;
  flex-wrap: wrap;
  width: 18rem;
  overflow: hidden;
  height: 6rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: stretch
}

.chessboard-container {
  display: flex;
  max-width: 927px;
  width: 90%;
  gap: 32px;
}

.left {
  display: flex;
  flex-direction: column;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 927px;
}

.chessboard-text-container {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  margin: 0;
}

.buttons {
  display: flex;
  gap: 16px;
}

.chessboard-title {
  margin-bottom: 16px;
  margin-top: 64px;
}

.dark-text {
  color: var(--default-opaque-neutrals-gray-90, #1A1A1A)!important;
}

@media screen and (max-width: 979px) {
  .right, .crown-subtitle {
    width: 90%;
  }
}

@media screen and (max-width: 969px) {
  .board-variants-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 822px) {
  .chessboard-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .chessboard-text-container {
    margin-top: 32px;
  }
}

@media screen and (max-width: 484px) {
  .subtitle {
    display: none;
  }
}

@media screen and (max-width: 484px) {
  .input-board {
    width: 16rem;
    height: 16rem;
  }

  .square {
    width: 2rem;
    height: 2rem;
  }

  .piece-shelf {
    width: 12rem;
    height: 4rem;
  }

  .piece-shelf-container {
    width: 90%;
  }
}

@media screen and (max-width: 371px) {
  .board-card pre {
    font-size: 16px!important;
  }
}

@media screen and (max-width: 484px) {
  .board-variants-container {
    width: 100%;
  }

  .board-card {
    width: 90%;
    justify-self: center;
  }
}