.discard-sign {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  background: #EDF2F7;
  text-align: center;
  flex-wrap: wrap;
}